<template>
  <v-container>
    <v-card>
      <v-progress-linear v-if="isLoading" indeterminate></v-progress-linear>
      <v-card-title>
        Nicht adressierbare E-Mails
      </v-card-title>
      <v-card-text>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header color="secondary">
              Zugewiesene E-Mails
            </v-expansion-panel-header>
            <v-expansion-panel-content >
              <MailLayout v-on:updateMails="getMails()" v-bind:mails="assignedMails"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels class="mt-3">
          <v-expansion-panel>
            <v-expansion-panel-header color="secondary">
              Nicht zugewiesene E-Mails
            </v-expansion-panel-header>
            <v-expansion-panel-content color="primary" >
              <MailLayout v-on:updateMails="getMails()" v-bind:mails="unassignedMails"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import MailLayout from "@/components/unassignedMails/MailLayout";
import {getMailsURL, requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";
import {sessionHandler} from "@/request/sessionHandler";
export default {
  name: "UnassignedMails",
  components: {MailLayout},
  data: () => ({
    assignedMails: [],
    unassignedMails: [],
    isLoading: false
  }),
  methods: {
    async getMails(){
      this.isLoading = false;
      await sessionHandler();
      await this.$http
          .get(
              getMailsURL(),
              {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              let tempUnassigned  = response.data.filter(mail => { return mail.claimedBy === null})
              let tempAssigned = response.data.filter(mail => { return mail.claimedBy !== null})

              this.assignedMails = tempAssigned
              this.unassignedMails = tempUnassigned
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Mail-gM", "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
    }
  },
  mounted() {
    this.getMails();
  }
}
</script>

<style scoped>

</style>
