<template>
  <v-card :color="mail.claimedBy === $RStore.user.Username ? 'primary' : ''">
    <v-card-title>
      {{ mail.subject }} <v-spacer></v-spacer><v-btn color="primary" v-if="mail.claimedBy !== $RStore.user.Username" @click="assignMailById(mail.id)">mir zuweisen</v-btn>
    </v-card-title>
    <v-card-subtitle>
      {{ parsedMail(mail.customerMailAddress) }}
    </v-card-subtitle>

    <v-card-text>
      <v-textarea :value="mail.body" readonly ></v-textarea>
    </v-card-text>
    <v-divider></v-divider>
    <div v-if="mail.attachments.length > 0" >
      <v-card-actions class="mt-2">
        <v-row>
          <v-col  v-for="att in mail.attachments" v-bind:key="att.id">
            <v-btn @click="download(att)" >{{ docNameSlicer(att.name) }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </div>
    <v-card-subtitle v-if="mail.attachments.length === 0">Es wurden keine Dokumente angehängt.</v-card-subtitle>
  </v-card>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {getAttachmentByIdURL, getClaimMailURL, requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";

export default {
  name: "MailCard",
  props: {
    mail: {
      required: true
    },
  },
  data: () => ({}),
  methods: {
    docNameSlicer(docName) {
      const length = docName.length - 25;
      if (docName.length > 35) {
        return docName.substring(length)
      }
      if (docName.length > 45) {
        return docName.substring(length)
      }
      return docName
    },
    parsedMail(mail){
      let splittedMail = mail.split("<");
      let uncleanMail = splittedMail[splittedMail.length-1]
      let cleanMail = uncleanMail.slice(0,uncleanMail.length-1)
      return cleanMail
    },
    async assignMailById(id){
      await sessionHandler();
      await this.$http
          .patch(
              getClaimMailURL(id),
              {}
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.$emit("updateMails")
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-gE2", "error");
            }
          })
    },
    async download(att){
      await sessionHandler();
      this.$http.get(getAttachmentByIdURL(att.id), {
        responseType: "blob",
        mode: requestMode(),
        headers: {
          'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
        },
      }).then((d) => {
        let filename = att.name;
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const blob = new Blob([d.data], {type: att.type || 'application/octet-stream'});
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // IE doesn't allow using a blob object directly as link href.
          // Workaround for "HTML7007: One or more blob URLs were
          // revoked by closing the blob for which they were created.
          // These URLs will no longer resolve as the data backing
          // the URL has been freed."
          window.navigator.msSaveBlob(blob, filename);
          return;
        }
        // Other browsers
        // Create a link pointing to the ObjectURL containing the blob
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);
        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(blobURL);
        }, 100);
      })
    }
  },
}
</script>

<style scoped>

</style>
